import React, { useState, useEffect } from 'react';
import moment from 'moment';
import './TimerUpgrade.css';

const TimerUpgrade = ({ serverTimeUTC }) => {
  // State to store time left
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });


  const calculateTimeLeft = () => {

    const now = moment();

    const endDate = moment(serverTimeUTC).add(60, 'days');
    
    // Calculate the difference between current time and the end date
    const difference = endDate.diff(now);

    if (difference > 0) {
      setTimeLeft({
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      });
    } else {
      // Countdown has ended
      setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    }
  };

  useEffect(() => {
    // Run countdown every second
    const timer = setInterval(calculateTimeLeft, 1000);
    
    // Cleanup on component unmount
    return () => clearInterval(timer);
  }, [serverTimeUTC]);

   // Check if the countdown has ended
   const hasTimeLeft = timeLeft.days > 0 || timeLeft.hours > 0 || timeLeft.minutes > 0 || timeLeft.seconds > 0;

   return (
     <div id="countdown">
        
    {!hasTimeLeft ?
        <h6>Waktu Upgrade telah habis</h6> : <></>
      }
       {hasTimeLeft ? (
         <>
           <h6>Ayo Upgrade Levelmu Sebelum:</h6>
           <div id="timer" className='countdown-container'>
             <div className="number-container day">
               <div className="number">{timeLeft.days}</div>
               <div className="text">Hari</div>
             </div>
             <div className="number-container hour">
               <div className="number">{timeLeft.hours}</div>
               <div className="text">Jam</div>
             </div>
             <div className="number-container minute">
               <div className="number">{timeLeft.minutes}</div>
               <div className="text">Menit</div>
             </div>
             <div className="number-container second">
               <div className="number">{timeLeft.seconds}</div>
               <div className="text">Detik</div>
             </div>
           </div>
         </>
       ) : null} {/* Do not display anything if time is up */}
     </div>
   );
};

export default TimerUpgrade;