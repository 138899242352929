const CurrencyConverter = (amount, conversionFactor, rev = 0) => {
  const currencies = conversionFactor && conversionFactor.currencies;
  const selectedCurrency = conversionFactor && conversionFactor.selectedCurrency;
  const defaultCurrency = conversionFactor && conversionFactor.defaultCurrency;
  let convertedAmount = null;
  let sourceRate = null;
  let targetRate = null;

  // Function to format the number to Indonesian Rupiah
  const numberDisplay = (num) => {
    if (isNaN(num)) return "0";
    if (num === 0) return "0";

    let number = Math.abs(num);
    const formattedNumber = number.toLocaleString('id-ID');
    return `${formattedNumber}`;
  };

  if (currencies) {
    for (const currency of currencies) {
      if (currency.code === defaultCurrency.code) {
        if (rev) {
          targetRate = currency?.value;
        } else {
          sourceRate = currency?.value;
        }
      }
      if (currency?.code === selectedCurrency?.code) {
        if (rev) {
          sourceRate = currency?.value;
        } else {
          targetRate = currency?.value;
        }
      }
    }

    if (sourceRate === null || targetRate === null) {
      return "0";
    }

    convertedAmount = (amount / sourceRate) * targetRate;
    return numberDisplay(convertedAmount);
  } else {
    return numberDisplay(amount);
  }
};

export default CurrencyConverter;