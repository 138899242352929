import { useTranslation } from "react-i18next";
import { decimalDisplay } from "../../utils/currencyNumberDisplay";
import "./LevelProgressMap.css";

const LevelProgressMap = ({ props }) => {
    const { t } = useTranslation();

    return (<>
       <div className="next_level_info">
                <h4>{t("Next Level:")} {props?.nextLevel}</h4>
                <p>{t("Points needed to achieve next level:")} {decimalDisplay(props?.nextPoint - props?.currentPoint)}</p>

                {/* Linear Progress Bar */}
                <div className="progress-bar-container">
                    <div className="progress-bar-background">
                        <div
                            className="progress-bar"
                            style={{
                                width: `${((props?.currentPoint / props?.nextPoint) * 100).toFixed(2)}%`
                            }}
                        />
                    </div>
                </div>
            </div>
    </>);
}

export default LevelProgressMap;