import React from "react";
import UserDropdown from "./DashboardUserDropdown";
import DoughnutChart from "./payoutOverviewChart";
import LinkSection from "./UserLinks";
import { useSelector } from "react-redux";
import LevelProgressMap from "./LevelProgressMap";
import TimerUpgrade from "./TimerUpgrade";

const RightContentSection = ({ props }) => {
  const currency = useSelector((state) => state.user?.selectedCurrency);
  const conversionFactor = useSelector((state) => state.user?.conversionFactor);
  // const serverTimeUTC = "2024-5-1 00:00:00";

  return (
    <>
      <UserDropdown props={props?.userProfile} />

      {/* <TimerUpgrade serverTimeUTC={props?.userProfile?.dateOfJoining} /> */}
      { props?.levelProgressMap.nextPoint != "-" &&
        (<TimerUpgrade serverTimeUTC={props?.userProfile?.dateOfJoining} />)}
      <LevelProgressMap props={props?.levelProgressMap} />
      <DoughnutChart
        pending={props?.payoutDoughnut?.pending}
        approved={props?.payoutDoughnut?.approved}
        payoutPaid={props?.payoutOverview?.payoutPaid}
        currency={currency}
        conversionFactor={conversionFactor}
      />
      {/* <LinkSection
        payoutTab={props?.payoutOverview}
        replicaLink={props?.replicaLink}
        leadCaptureLink={props?.leadCaptureLink}
        currency={currency}
        conversionFactor={conversionFactor}
      /> */}
    </>
  );
};

export default RightContentSection;