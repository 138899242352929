import { useTranslation } from "react-i18next";
import "./RankProgressMap.css";

const RankProgressMap = ({ rank, currentUserPoint }) => {
    const { t } = useTranslation();

    const { rankCriteria } = rank;
    const { personalPv, groupPv, leftPv, rightPv, downlineRanks, downlinePackages } = rankCriteria;

    const { currentLeft, currentRight, currentPersonalPv, currentGroupPv } = currentUserPoint;

    // Calculating progress percentages with a cap at 100%
    const personalPvProgress = Math.min((currentPersonalPv / personalPv) * 100, 100);
    const groupPvProgress = Math.min((currentGroupPv / groupPv) * 100, 100);
    const leftPvProgress = Math.min((currentLeft / leftPv) * 100, 100);
    const rightPvProgress = Math.min((currentRight / rightPv) * 100, 100);

    return (
        <div className="rank-progress-map">
            {/* Personal Pv Progress */}
            <div className="progress-section">
                <div className="progress-title">
                    <h5>{t("Personal Pv")}</h5>
                </div>
                <div className="rank-progress-bar-container">
                    <div className="rank-progress-bar-background">
                        <div className="progress-bar" style={{ width: `${personalPvProgress.toFixed(2)}%` }}>
                            <div className="progress-bar-text">
                                {currentPersonalPv} / {personalPv}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Group Pv Progress */}
            <div className="progress-section">
                <div className="progress-title">
                    <h5>{t("Group Pv")}</h5>
                </div>
                <div className="rank-progress-bar-container">
                    <div className="rank-progress-bar-background">
                        <div className="progress-bar" style={{ width: `${groupPvProgress.toFixed(2)}%` }}>
                            <div className="progress-bar-text">{currentGroupPv} / {groupPv}</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Left Pv Progress */}
            <div className="progress-section">
                <div className="progress-title">
                    <h5>{t("Left Pv")}</h5>
                </div>
                <div className="rank-progress-bar-container">
                    <div className="rank-progress-bar-background">
                        <div className="progress-bar" style={{ width: `${leftPvProgress.toFixed(2)}%` }}>
                            <div className="progress-bar-text">{currentLeft}/{leftPv}</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Right Pv Progress */}
            <div className="progress-section">
                <div className="progress-title">
                    <h5>{t("Right Pv")}</h5>
                </div>
                <div className="rank-progress-bar-container">
                    <div className="rank-progress-bar-background">
                        <div className="progress-bar" style={{ width: `${rightPvProgress.toFixed(2)}%` }}>
                            <div className="progress-bar-text">{currentRight} / {rightPv}</div>
                        </div>
                    </div>
                </div>
            </div>

            {downlineRanks && downlineRanks.length > 0 && (
                <>
                    <hr />
                    <h6>Downline Rank Count</h6>
                    {downlineRanks.map(e => {
                        const progress = Math.min((e.usersCount / e.count) * 100, 100);

                        return (<>
                            <div className="progress-section">
                                <div className="progress-title">
                                    <h5>{e.rankName}</h5>
                                </div>
                                <div className="rank-progress-bar-container">
                                    <div className="rank-progress-bar-background">
                                        <div className="progress-bar" style={{ width: `${progress.toFixed(2)}%` }}>
                                            <div className="progress-bar-text">{e.usersCount} / {e.count}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)
                    })}</>
            )}
            {downlinePackages && downlinePackages.length > 0 && (
                <>
                    <hr />
                    <h6>Downline Package Count</h6>
                    {downlinePackages.map(e => {
                        const progress = Math.min((e.usersCount / e.count) * 100, 100);

                        return (<>
                            <div className="progress-section">
                                <div className="progress-title">
                                    <h5>{e.packageName}</h5>
                                </div>
                                <div className="rank-progress-bar-container">
                                    <div className="rank-progress-bar-background">
                                        <div className="progress-bar" style={{ width: `${progress.toFixed(2)}%` }}>
                                            <div className="progress-bar-text">{e.usersCount} / {e.count}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>)
                    })}
                </>
            )}


        </div>
    );
}

export default RankProgressMap;