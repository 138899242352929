import { clearCookie, getCookie } from "./cokkieUtils";

export const setLoginResponseCheck = () => {
    const accessToken = localStorage.getItem('access-token') ?? getCookie('access_token');
    const user = localStorage.getItem('user') ?? getCookie('user');
    const defaultCurrency = localStorage.getItem('defaultCurrency') ?? getCookie('defaultCurrency');
    const defaultLanguage = localStorage.getItem('defaultLanguage') ?? getCookie('defaultLanguage');
    if (!accessToken || !user || !defaultCurrency || !defaultLanguage) {
        localStorage.clear();
        clearCookie('user');
        clearCookie('defaultCurrency');
        clearCookie('defaultLanguage');
        clearCookie('access_token');
    }
    const data = { accessToken, user, defaultCurrency, defaultLanguage };

    return data
}


