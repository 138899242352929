import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton"; // Assuming you're still using this somewhere
import { ApiHook } from "../../hooks/apiHook";

const PackageComponent = ({ packages, currentPackage }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("package");
    const [displayedPackageId, setDisplayedPackageId] = useState(null);
    const [packageCheck, setPackageCheck] = useState(false);

    // API call to fetch package overview
    const packageOverview = ApiHook.CallPackageOverview(packageCheck, setPackageCheck);

    const handleTabChange = (tabId) => {
        if (tabId === "package-overview") {
            setPackageCheck(true);
        }
        setActiveTab(tabId);
    };

    // Sort packages by pairValue in ascending order
    const sortedPackages = packages?.sort((a, b) => a.pairValue - b.pairValue) || [];

    // Preload images when packages change
    useEffect(() => {
        if (packages) {
            sortedPackages.forEach(pkg => {
                const img = new Image();
                img.src = pkg.image ?? "/images/default-package-icon.png"; // Preload each image
            });
        }
    }, [packages]);

    // Find the index of the current package and displayed package
    const currentPkgIndex = sortedPackages.findIndex(pkg => pkg.id === currentPackage);
    const displayedPkgIndex = sortedPackages.findIndex(pkg => pkg.id === displayedPackageId);

    const showPreviousPackageClick = () => {
        if (displayedPkgIndex > 0) {
            setDisplayedPackageId(sortedPackages[displayedPkgIndex - 1].id);
        }
    };

    const showNextPackageClick = () => {
        if (displayedPkgIndex < sortedPackages.length - 1) {
            setDisplayedPackageId(sortedPackages[displayedPkgIndex + 1].id);
        }
    };

    // Set initial displayedPackageId based on currentPackage
    useEffect(() => {
        if (currentPackage) {
            setDisplayedPackageId(currentPackage);
        }
    }, [currentPackage]);

    const renderPackage = () => {
        const displayedPkg = sortedPackages[displayedPkgIndex];
        if (!displayedPkg) return null;

        let status;
        if (displayedPkg.id === currentPackage) {
            status = "current";
        } else if (displayedPkgIndex < currentPkgIndex) {
            status = "previous";
        } else {
            status = "next";
        }

        return (
            <div className={`ranking-icon rankingbox${displayedPkg.id}`}>
                <p>{t("package")} {t(status)}</p>
                <img src={displayedPkg.image ?? "/images/ranking-icon.png"} alt={displayedPkg.name} />
                <p>{displayedPkg.name}</p>
                <p>{t("pairValue")}: {displayedPkg.pairValue}</p>
            </div>
        );
    };

    return (
        <div className="col-md-6">
            <div className="ranking-box">
                <div className="joinings_viewBox_head">
                    <h5>{t("package")}</h5>
                </div>
                <ul className="teamPerfomance_tab nav nav-tabs mb-3" id="ex1" role="tablist">
                    <li className="nav-item" role="presentation">
                        <Link className={`nav-link ${activeTab === "package" ? "active" : ""}`} id="ex1-tab-1" onClick={() => handleTabChange("package")}>
                            {t("package")}
                        </Link>
                    </li>
                    <li className="nav-item" role="presentation">
                        <Link className={`nav-link ${activeTab === "package-overview" ? "active" : ""}`} id="ex1-tab-2" onClick={() => handleTabChange("package-overview")}>
                            {t("package_overview")}
                        </Link>
                    </li>
                </ul>
                <div className="tab-content" id="ex1-content">
                    <div className={`tab-pane ${activeTab === "package" ? "active" : ""}`} id="package" role="tabpanel" aria-labelledby="package">
                        {renderPackage()}
                        {displayedPkgIndex > 0 && (
                            <div className="previcon">
                                <a href="#/" onClick={showPreviousPackageClick}>
                                    <img src="/images/prev-icon.svg" alt="" />
                                </a>
                            </div>
                        )}
                        {displayedPkgIndex < sortedPackages.length - 1 && (
                            <div className="nxt-icon">
                                <a href="#/" onClick={showNextPackageClick}>
                                    <img src="/images/nxt-icon.svg" alt="" />
                                </a>
                            </div>
                        )}
                    </div>
                    <div className={`tab-pane ${activeTab === "package-overview" ? "active" : ""}`} id="package-overview" role="tabpanel" aria-labelledby="package-overview">
                    <div className="top_recuirters_section">
                            {!packageOverview.data ? (
                                <div className="teammbrs_cnt_row">
                                    <div className="teammbrs_cnt_img">
                                        <Skeleton circle width="45px" height="45px" containerClassName="avatar-skeleton" count={4} />
                                    </div>
                                    <div className="teammbrs_cnt_name_dtl">
                                        <div className="teammbrs_cnt_name">
                                            <Skeleton count={9} />
                                        </div>
                                    </div>
                                    <div className="teammbrs_cnt_img">
                                        <Skeleton circle width="45px" height="45px" containerClassName="avatar-skeleton" count={4} />
                                    </div>
                                </div>
                            ) : (
                                packageOverview.data?.map((member, index) => (
                                    <div key={index} className="teammbrs_cnt_row">
                                        <div className="teammbrs_cnt_img">
                                            <img src={member.image ?? "/images/team3.png"} alt="" />
                                        </div>
                                        <div className="teammbrs_cnt_name_dtl" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                            <div className="teammbrs_cnt_name">{member.name}</div>
                                            <div className="top_recuirters_num">
                                                <span>{member.count}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PackageComponent;