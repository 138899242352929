import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import './RankProgressMap.css';

const PoolBonusComponent = ({ poolBonus }) => {
    const { t } = useTranslation();

    const [poolIndex, setPoolIndex] = useState(0);

    // Check if poolBonus or poolTarget is empty
    if (!poolBonus || !poolBonus.poolTarget || poolBonus.poolTarget.length === 0) {
        return (
            <div className="col-md-5">
                <div className="ranking-box">
                    <h5 style={{ fontSize: 18 }}>{t("Pool Bonus")}</h5>
                    <div className="pool-bonus-component">
                        <p style={{ textAlign: "center", margin: "20px 0px" }}>{t("No data available.")}</p>
                    </div>
                </div>
            </div>
        ); // Return a card with a message when no data is available
    }

    const poolTargets = poolBonus.poolTarget;

    const showPreviousPool = () => {
        if (poolIndex > 0) {
            setPoolIndex(poolIndex - 1);
        }
    };

    const showNextPool = () => {
        if (poolIndex < poolTargets.length - 1) {
            setPoolIndex(poolIndex + 1);
        }
    };

    const currentPool = poolTargets[poolIndex];

    const poolLeftProgress = Math.min((poolBonus.poolLeft / currentPool.poolLeft) * 100, 100);
    const poolRightProgress = Math.min((poolBonus.poolRight / currentPool.poolRight) * 100, 100);
    const poolPvProgress = Math.min((poolBonus.poolPv / currentPool.poolPv) * 100, 100);

    return (
        <div className="col-md-5">
            <div className="ranking-box">
                <div className="pool-bonus-component ">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h5 style={{ fontSize: 18 }}>{t("Pool Bonus")}</h5>
                        <div className="pool-navigation" style={{ display: "flex", gap: 20 }}>
                            {poolIndex > 0 && (
                                <a href="#/" className="prev" style={{
                                    width: "35px",
                                    height: "35px",
                                }} onClick={showPreviousPool}>
                                    <img style={{ width: "100%" }} src="/images/prev-icon.svg" alt="" />
                                </a>
                            )}
                            {poolIndex < poolTargets.length - 1 && (
                                <a href="#/" className="next" style={{
                                    width: "35px",
                                    height: "35px",
                                }} onClick={showNextPool}>
                                    <img style={{ width: "100%", }} src="/images/nxt-icon.svg" alt="" />
                                </a>
                            )}
                        </div>
                    </div>

                    <div className="pool-info">
                        <h5 style={{ textAlign: "center", margin: "20px 0px" }}>{t(currentPool.name)}</h5>
                        <div className="progress-section">
                            <div className="progress-title">
                                <h5>{t("Current Left")}</h5>
                            </div>
                            <div className="rank-progress-bar-container">
                                <div className="rank-progress-bar-background">
                                    <div className="progress-bar" style={{ width: `${poolLeftProgress.toFixed(2)}%` }}>
                                        <div className="progress-bar-text">
                                            {poolBonus.poolLeft} / {currentPool.poolLeft}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="progress-section">
                            <div className="progress-title">
                                <h5>{t("Current Right")}</h5>
                            </div>
                            <div className="rank-progress-bar-container">
                                <div className="rank-progress-bar-background">
                                    <div className="progress-bar" style={{ width: `${poolRightProgress.toFixed(2)}%` }}>
                                        <div className="progress-bar-text">
                                            {poolBonus.poolRight} / {currentPool.poolRight}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="progress-section">
                            <div className="progress-title">
                                <h5>{t("Personal PV")}</h5>
                            </div>
                            <div className="rank-progress-bar-container">
                                <div className="rank-progress-bar-background">
                                    <div className="progress-bar" style={{ width: `${poolPvProgress.toFixed(2)}%` }}>
                                        <div className="progress-bar-text">
                                            {poolBonus.poolPv} / {currentPool.poolPv}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PoolBonusComponent;
