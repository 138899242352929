

export function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export function clearCookie(name) {
    const domain =
        window.location.hostname === "localhost" ? "localhost" : ".shopbiz.id";
    document.cookie = `${name}=; Max-Age=0; path=/; domain=${domain};`;
}